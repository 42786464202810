const contentPolicy = {
    privacy_policy: {
        title: "개인정보 처리방침",
        body: `<p>(주)엔액스엔랩스(이하 회사)은 「개인정보 보호법」에 따라 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립 및 공개합니다.</p>
                
      <br />
      <h2>제1조 (개인정보의 처리목적)</h2>
      <p>① 회사는 다음의 목적을 위하여 개인정보를 처리하며, 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않습니다. 이용 목적이 변경되는 경우에는 「개인정보 보호법」에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
      <p>또한 회사는 만 14세 이상의 이용자를 대상으로 서비스를 제공하며, 만 14세 미만 아동의 개인정보가 수집된 사실을 인지하는 경우 해당 정보를 지체없이 삭제하겠습니다.</p>
      <ol>
          <li>단순 연락처 수집 및 수요 파악: 회원 가입이나 서비스 제공 목적이 아닌, 단순 연락처 수집 및 수요 파악을 위해 개인정보를 처리합니다.</li>
      </ol>
      
      <br />
      <h2>제2조 (개인정보의 처리 및 보유기간)</h2>
      <p>① 회사는 법령에 명시되어 있거나 이용자로부터 개인정보 수집 시 동의 받은 개인정보의 보유 및 이용기간 내에서 개인정보를 처리 및 보유합니다.</p>
      <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
      <ol>
          <li>단순 연락처 수집 및 수요 파악: 수집 목적 달성 시까지
              <p>(단, 개인정보 도용 등으로 인한 피해 발생 시 복구와 피해자 보호 등을 위하여 회원가입 시 수집한 개인정보를 14일 동안 임시로 보관할 수 있습니다)</p>
              <p>다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지</p>
              <ol type="1">
                  <li>관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료 시 까지</li>
                  <li>서비스 이용에 따른 채권, 채무관계 잔존 시에는 해당 채권, 채무관계 정산 시까지</li>
              </ol>
          </li>
          <table border="1">
          <thead>
              <tr>
                  <th>관련법령</th>
                  <th>수집정보</th>
                  <th>보유기간</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>통신비밀보호법</td>
                  <td>서비스 이용 관련 정보(접속로그)</td>
                  <td>3개월</td>
              </tr>
              <tr>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                  <td>3년</td>
              </tr>
              <tr>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  <td>계약 또는 청약철회 등에 관한 기록</td>
                  <td>5년</td>
              </tr>
              <tr>
                  <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                  <td>5년</td>
              </tr>
          </tbody>
      </table>

      </ol>

      <br />
      <h2>제3조 (개인정보의 제3자 제공)</h2>
      <p>① 회사는 이용자의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 이용자의 동의, 법률의 특별한 규정 등 「개인정보 보호법」에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
      <p>② 회사는 제3자에게 개인정보를 제공하지 않으며, 제공하게 될 경우 이용자에게 동의를 받겠습니다.</p>

      <br />
      <h2>제4조 (개인정보처리의 위탁)</h2>
      <p>① 서비스 제공을 위해 처리하는 위탁업무는 없습니다.</p>
      <p>② 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>

      <br />
      <h2>제5조 (이용자와 법정대리인의 권리•의무 및 행사방법)</h2>
      <p>① 이용자는 회사에 대해 언제든지 개인정보 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
      <p>② 제1항에 따른 권리 행사는 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.</p>
      <p>③ 정보 열람 및 처리정지 요구는 개인정보보호법에 의하여 이용자의 권리가 제한될 수 있습니다.</p>
      <p>④ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
      <p>⑤ 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인할 수 있습니다.</p>
      <p>⑥ 제4항에 따른 권리행사 주체가 대리인(이용자의 법정대리인이나 위임을 받은 자)인 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>

      <br />
      <h2>제6조 (처리하는 개인정보 항목)</h2>
      <p>① 회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
      <table border="1">
          <thead>
              <tr>
                  <th> 구분 </th>
                  <th> 수집·이용목적 </th>
                  <th> 필수/선택 </th>
                  <th> 수집 및 이용항목 </th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td> 개인 전화번호 </td>
                  <td> 수요 파악 및 잠재고객 연락처 수집 </td>
                  <td> 필수 </td>
                  <td> 전화번호 </td>
              </tr>
          </tbody>
      </table>
      <p>② 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.</p>
      <p>IP주소, 쿠키, 서비스 이용기록, 메타 데이터</p>
      <p>③ 서비스 이용과정에서 개인정보는 아니지만 아래의 정보가 생성되어 수집될 수 있습니다.</p>
      <p>광고식별자, 방문기록, 기기 정보(유형, 모델명, OS 버전, APP 버전, 기기 식별자, 언어 및 국가)</p>

      <br />
      <h2>제7조 (개인정보의 파기)</h2>
      <p>① 회사는 이용자로부터 동의 받은 개인정보의 보유기간이 경과하거나 처리목적을 달성하여 더 이상 개인정보가 필요하지 않게 된 경우 지체없이 해당 개인정보를 파기합니다.</p>
      <p>② 제1항 해당됨에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. (법령에 따른 보관 기간은 제2조를 참조하시기 바랍니다)</p>
      <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
      <ol>
          <li>파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
          <li>파기방법: 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</li>
      </ol>

      <br />
      <h2>제8조 (개인정보의 안전성 확보조치)</h2>
      <p>① 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
      <ol>
          <li>관리적 조치: 내부관리계획 수립 및 시행, 정기적 임직원 교육, 정기적인 접속기록 점검, 수탁사 점검 등</li>
          <li>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 내부 관리계획에 따른 암호화 (전송구간 및 저장 시), 보안프로그램 설치 등</li>
          <li>물리적 조치: 데이터센터, 자료보관실 등의 접근통제 등</li>
      </ol>

      <br />
      <h2>제9조 (개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</h2>
      <p>① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
      <ol>
          <li>
              쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 기기 내 저장공간에 저장되기도 합니다.
              <ol type="a">
                  <li>쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보와 광고 (개인 맞춤형 광고 포함)의 제공을 위해 사용됩니다.</li>
                  <li>
                      쿠키의 설치∙운영 및 거부:
                      <ul>
                          <li>Internet Explorer: [도구>인터넷 옵션>개인정보>고급]에서 쿠키의 사용 거부</li>
                          <li>MS Edge: 웹 브라우저 상단의 “설정 및 기타” > “설정” > “쿠키 및 사이트 권한” > 쿠키 및 저장된 데이터 등에 관한 개인 설정 입력</li>
                          <li>Chrome: [설정>개인정보 및 보안>쿠키 및 기타 사이트 데이터>일반 설정]에서 쿠키의 사용 거부</li>
                          <li>Android: [설정>인터넷 설정>개인정보 보호 및 보안>기타] 에서 “쿠키 허용” 거부</li>
                          <li>iOS: [설정>Safari>개인정보 보호 및 보안]에서 “모든 쿠키 차단” 선택</li>
                      </ul>
                  </li>
                  <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
              </ol>
          </li>
          <li>
              분석 소프트웨어는 이용자가 웹 사이트를 방문하거나 모바일 서비스를 이용할 때 자동으로 생성되는 정보를 분석하기 위해 사용되며, 이용자는 이를 거부할 수 있습니다.
              <ul>
                  <li>Android: [설정>인터넷 설정>개인정보 보호 및 보안] 에서 “스마트 추적 방지” 또는 “사이트에서 추적하지 못하도록 요청” 선택</li>
                  <li>iOS: [설정>개인정보 보호> 추적]에서 “앱이 추척을 요청하도록 허용” 선택 해제</li>
                  <li>Internet Explorer: 웹 브라우저 상단의 “도구” 메뉴 > “안전” 메뉴 > “Do Not Track 요청 켜기” 선택</li>
                  <li>MS Edge: 웹 브라우저 상단의 “설정 및 기타” > “설정” > “개인정보, 검색 및 서비스” > “추적 방지” 끄기 또는 “개인정보” > “추적 안 함” 요청 보내기 선택</li>
                  <li>Chrome: [설정>개인정보 및 보안>쿠키 및 기타 사이트 데이터>일반 설정]에서 탐색 트래픽과 함께 '추적 안함' 요청 선택</li>
              </ul>
          </li>
      </ol>

      <br />
      <h2>제10조 (개인정보 보호책임자)</h2>
      <p>① 회사는 개인정보 처리에 관한 업무를 총괄하고 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
      <p>이름: 이재원</p>
      <p>소속: 엔액스엔랩스</p>
      <p>직위: 대표</p>
      <p>연락처: nxn.ai.official@gmail.com</p>
      <p>② 이용자께서는 회사의 서비스를 이용하시면서 발생한 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항에 대하여 개인정보 보호책임자에게 문의하실 수 있으며, 문의하신 내용은 빠른 시일 내에 답변 및 처리해드릴 예정입니다. 다만, 부득이한 사유로 지연이 될 경우 이용자께 다시 안내 드리겠습니다.</p>

      <br />
      <h2>제11조 (권익침해 구제방법)</h2>
      <p>이용자는 개인정보 침해에 대한 피해구제나 상담 등이 필요하신 경우에 아래의 기관에 문의하실 수 있습니다</p>
      <table border="1">
        <thead>
            <tr>
                <th>기관명</th>
                <th>홈페이지</th>
                <th>전화</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>개인정보 침해신고센터</td>
                <td><a href="http://privacy.kisa.or.kr">privacy.kisa.or.kr</a></td>
                <td>(국번없이) 118</td>
            </tr>
            <tr>
                <td>개인정보 분쟁조정위원회</td>
                <td><a href="http://www.kopico.go.kr">www.kopico.go.kr</a></td>
                <td>(국번없이) 1833-6972</td>
            </tr>
            <tr>
                <td>대검찰청</td>
                <td><a href="https://www.spo.go.kr/site/spo/main.do">www.spo.go.kr/site/spo/main.do</a></td>
                <td>(국번없이) 1301</td>
            </tr>
            <tr>
                <td>경찰청</td>
                <td><a href="https://ecrm.police.go.kr/minwon/main">ecrm.police.go.kr/minwon/main</a></td>
                <td>(국번없이) 182</td>
            </tr>
        </tbody>
      </table>


      <br />
      <h2>제12조 본 개인정보처리방침의 적용 범위</h2>
      <p>① 본 개인정보처리방침은 회사가 제공하는 모든 서비스(웹사이트 및 APP 포함)에 적용되지만, 서비스의 특성이 상이한 경우, 서비스별로 특화된 개인정보처리방침이 적용될 수 있습니다.</p>
      <p>② 회사 서비스에 링크되어 있는 다른 회사의 웹사이트를 방문하거나, 방문하신 웹사이트에서 개인정보를 수집하는 경우 본 개인정보처리방침이 적용되지 않습니다.</p>

      <br />
      <h2>제13조 (개인정보 처리방침의 변경 및 고지)</h2>
      <p>① 본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 하겠습니다. 또한 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 필요 시 이용자 동의를 다시 받을 수도 있습니다.</p>
      <p>② 이 개인정보 처리방침은 2023.12.08부터 적용됩니다.</p>
      
      `,
    },
    service_policy: {
        title: "서비스이용약관",
        body: `<h2>1. AI 서비스 이용 관련 제한</h2>
      <p>1) AI 기술을 이용하여 결과물을 생성하는 서비스(AI 아바타 등을 포함하며 이에 한정되지 아니함, 이하 “HeyPop AI 서비스”)는 만 19세 이상의 성년만 이용이 가능합니다.</p>
      <p>2) 귀하는 HeyPop AI 서비스를 사용하기 위하여 귀하의 사진을 제출하여야 하며, 회사가 귀하의 사진을 사용하여 AI 결과물을 생성하는 목적으로만 이를 학습에 사용하는 것에 동의합니다. 귀하가 제출한 사진은 AI 결과물 생성 후 즉시 삭제됩니다.</p>
      <p>3) 귀하는 귀하가 제출하는 사진 및 생성된 결과물을 본 이용약관(특히, 4. 커뮤니티 가이드라인 및 7. 금지된 활동)에 위반되지 않도록 해야 하고, 이를 위반하여 법적 분쟁, 사회적 이슈 등이 발생한 경우 지체 없이 회사에 해당 사실을 통지하고, 회사를 면책하는데 동의합니다.</p>
      <p>4) 귀하가 본 이용약관을 위반할 경우 회사의 요청에 따라 결과물을 삭제할 의무를 부담하고, 회사는 귀하의 HeyPop AI 서비스 이용을 중단하는 조치를 할 수 있습니다.</p>
      <p>5) HeyPop AI 서비스로 생성되는 결과물은 AI에 의하여 임의로 만들어지므로, 회사는 귀하에게 결과물의 적법성 또는 적합성에 대해서 보증하지 않고, 이에 따른 책임을 부담하지 않습니다. 또한, 회사는 귀하가 결과물로 인해 야기되는 법률적, 윤리적, 사회적, 정치적 영향에 대하여 책임이 없습니다.</p>`,
    },
    collection_and_use_info_policy: {
        title: "개인정보 수집 및 이용 안내",
        body: `<p>(주)엔액스엔랩스는 당사 서비스에 대한 홍보를 위해 개인정보를 수집 및 이용합니다.</p> 
            <p>내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.</p>        
                <br />
                <h3>1. 수집하는 개인 정보 항목 및 이용 목적</h3>
                <table border="1">
                    <thead>
                        <tr>
                            <th> 구분 </th>
                            <th> 수집·이용목적 </th>
                            <th> 필수/선택 </th>
                            <th> 수집 및 이용항목 </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> 개인 전화번호 </td>
                            <td> 수요 파악 및 잠재고객 연락처 수집 </td>
                            <td> 필수 </td>
                            <td> 전화번호 </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                
                <br />
                <h3>2. 보유 및 이용기간</h3>
                <p>사전예약 신청자 전화번호: 삭제 요청시까지 (최대 1년)</p>
                
                <p>이용자의 동의는 해당 서비스 사전홍보기간 종료까지 유지되며, 개인정보의 수집 및 이용에 대한 동의를 거부할 수 있습니다.</p>
                <p>다만, 거부하시는 경우 당사 서비스에 대한 사전예약 신청이 이뤄질 수 없습니다.</p>`,
    },
};

export default contentPolicy;
export const pageVariants = {
  initial: {
    opacity: 0,
    pointerEvents: "none"
  },
  in: {
    opacity: 1,
    pointerEvents: "auto",
    transition: {
      duration: 0.8,
    },
  },
  out: {
    opacity: 0,
    pointerEvents: "none",
    transition: {
      duration: 0.8,
    },
  },
};

/* Mixpanel.js */
import mixpanel from 'mixpanel-browser';
mixpanel.init('8b95d04a3eab456d0d6f917e101aae3c');

// let env_check = process.env.NODE_ENV === 'production';
let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  get_distinct_id: () => {
    if (env_check) mixpanel.get_distinct_id();
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
    increment: (props, number) => {
      if (env_check) mixpanel.people.increment(props, number);
    },
    set_once: (props) => {
      if (env_check) mixpanel.people.set_once(props);
    },
  },
  register: (props) => {
    if (env_check) mixpanel.register(props);
  },
  register_once: (props) => {
    if (env_check) mixpanel.register_once(props);
  },
  get_property: (props) => {
    if (env_check) mixpanel.get_property(props);
  }
};

export let Mixpanel = actions;
import React from 'react';
import './PopUp.css'; // Assume you have a separate CSS file for the popup
import logo from '../assets/Logo_large.png';
import { motion, AnimatePresence } from "framer-motion"
import { pageVariants } from "../animation/variants";

function PopUp({ show, onClose }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="popup-overlay"
          variants={pageVariants}
          initial="initial"
          animate="in"
          exit="out"
          onClick={onClose}
        >
          <motion.div
            className="popup-content"
            variants={pageVariants}
            initial="initial"
            animate="in"
            exit="out"
            onClick={e => e.stopPropagation()}
          >
            <div className="popup-inner">
              <img src={logo} alt="Logo" className="popup-logo" />
              <p className="popup-text">앱 사전 출시 알림을 <br/> 신청해주셔서 감사합니다</p>
              <strong className="popup-strong">헤이팝의 최신 소식을 <br/> 가장 먼저 알려드릴게요!</strong>
              <button className="popup-button" onClick={onClose}>확인</button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default PopUp;

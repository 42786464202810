// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Lookbook from './pages/Lookbook';
import Tryon from './pages/Tryon';
import Entertainment from './pages/Entertainment';
import PolicyPage from './pages/PolicyPage';

function App() {

  const policyRoutesMap = [
    { path: '/policy/service', type: 'service_policy' },
    { path: '/policy/privacy', type: 'privacy_policy' },
    { path: '/policy/collection_and_use_info', type: 'collection_and_use_info_policy' },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Lookbook />} />
        <Route path="/lookbook" element={<Lookbook />} />
        <Route path="/tryon" element={<Tryon />} />
        <Route path="/entertainment" element={<Entertainment />} />
        {policyRoutesMap.map(route => (
          <Route key={route.path} path={route.path} element={<PolicyPage pageType={route.type} />} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;

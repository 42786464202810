import React from "react";
import styled from "styled-components";
import contentPolicy from "../assets/contentPolicy";
import { pageVariants } from "../animation/variants";
import { motion } from "framer-motion";

export default function PolicyPage({ pageType }) {
  const content = contentPolicy[pageType];

  return (
    <MainContainer
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <ContentTitle>{content.title}</ContentTitle>
      <ContentBody><div dangerouslySetInnerHTML={{ __html: content.body }} /></ContentBody>
    </MainContainer>
  );
}

const MainContainer = styled(motion.div)`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    min-height: 100vh;
`;

const ContentTitle = styled.h1`
    font-size: 28px;
    margin-bottom: 20px;
`;

const ContentBody = styled.p`
    font-size: 16px;
    color:black !important;
`;
